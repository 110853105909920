//Interface
export interface InterfaceWeb3 {
  isLogin: boolean;
  isWalletConnect: boolean;
  chainId: number;
  account: string;
}
//state
export const initialState = (): InterfaceWeb3 => ({
  isLogin: false,
  isWalletConnect: false,
  account: '',
  chainId: 0,
});

export default initialState;
