//import _ from 'lodash'
import Web3 from 'web3';
import { IContract, Unit } from './interfaces';
import { INetWork } from './interfaces';
import { supportChains, smcMode, ABI, CONTRACT_LIST, NETWORKS, NETWORK_CLAIM, NETWORK_MIGRATION } from './config';
import { CONTRACT_NAME } from './const';

export function w3FromWei(price: string, tokenWei: Unit = 'ether'): number {
  if (!price) return 0.0;
  return parseFloat(Web3.utils.fromWei(price, tokenWei));
}

export function w3toWei(price: string, tokenWei: Unit = 'ether') {
  return Web3.utils.toWei(price, tokenWei);
}

export function shortenWallet(address: string) {
  if (!!address) return `${address.substring(0, 5)}...${address.substring(address.length - 4)}`;
  return '...';
}

export function formatTime(unix: number) {
  const date = new Date(unix * 1000);
  return date.toLocaleString('en-US');
}

export function shortNumber(numberV: number, withAbbr = true, decimals = 2) {
  if (!numberV) numberV = 0;
  const COUNT_ABBR = ['', 'K', 'M', 'B', 'T'];
  const i = numberV < 1000 ? 0 : Math.floor(Math.log(numberV) / Math.log(1000));
  const result = (numberV / Math.pow(1000, i)).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
  if (withAbbr) return `$${result}${COUNT_ABBR[i]}`;
  return result;
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export async function copyToClipboard(subject: string, content: string) {
  try {
    await navigator.clipboard.writeText(content);
    showNotification(subject + ' has been copied');
  } catch ($e) {
    showNotification('Copy to clipboard error', false);
  }
}

export function showNotification(content: string, isSuccess: boolean = true) {
  const $toast = window.$nuxt.$toast;
  if (isSuccess) $toast.success(content);
  else $toast.error(content);
}

export function formatNumber(numberV: number, isFloat: boolean, locale: string) {
  if (!isFloat) return Math.round(numberV).toLocaleString(locale);

  //if (numberV < 100000)
  //numberV += 0.0240;
  let floatV = Math.round(numberV * 10000) % 10000;
  if (floatV)
    return numberV.toLocaleString(locale, {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4
    });
  return Math.round(numberV).toLocaleString(locale);
}

export function floatNumber(numberV: number) {
  let fRw = Math.round(numberV * 10000) % 10000;
  //if (numberV > 100000)
  //fRw += 0.0240;
  if (fRw) return '.' + String(fRw).padStart(4, '0');
  else return false;
}

export function weiToFixed(x: any) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
}

export const padLeft = (number: number, length: number, character: string = '0'): string => {
  let result = String(number);
  for (let i = result.length; i < length; ++i) {
    result = character + result;
  }
  return result;
};

export const getRPCs = Object.values(NETWORKS).reduce((acc: Record<number, string>, cur: INetWork) => {
  acc[cur.chainId] = cur.rpcUrl;
  return acc;
}, {});

// export const getRPCs = () =>
//   supportChains[`${smcMode}`].reduce((acc: any, cur: any) => {
//     acc[`${cur.chainId}`] = cur.rpcUrl;
//     return acc;
//   }, {});

export const getConfigChain = (chainId: number = 0): INetWork => {
  if (chainId === 0) {
    return supportChains[`${smcMode}`][0];
  } else {
    const network = Object.values(NETWORKS).find(function (item: INetWork) {
      return item.chainId === chainId;
    });
    return network as INetWork;
  }
};

export const getChainIdSupport = (): number[] =>
  supportChains[`${smcMode}`].reduce((acc: number[], cur: INetWork) => {
    acc.push(cur.chainId);
    return acc;
  }, []);

export const getChainSupport = () =>
  supportChains[`${smcMode}`].reduce((acc: number[], cur: INetWork) => {
    acc.push(cur.chainId);
    return acc;
  }, []);

export const isChainSupport = (chainId: number) => supportChains[`${smcMode}`].some((item: any) => item.chainId === chainId);

export const isFunction = (functionToCheck: any) => {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

export function isDeviceMobileAndTablet(ctx: any) {
  const { $device } = ctx;
  return $device.isMobile || $device.isTablet;
}

export function infoBrowser() {
  let deviceName = 'Unknown';
  let browserName = 'Unknown';
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const browserInfo = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  const deviceInfo = userAgent.match(/(iphone|ipad|ipod|android|windows phone|blackberry)/i);
  if (browserInfo) browserName = browserInfo[0] as string;
  if (deviceInfo) deviceName = deviceInfo[0] as string;
  else deviceName = platform as string;
  return {
    deviceName,
    browserName
  };
}
export function showLog(nameLog: string, contentLog: any = '') {
  if (process.env.NODE_ENV === 'development') {
    console.log(`${nameLog}:`, contentLog);
  }
}

export function getAbiByContract(addr: string) {
  const contract = CONTRACT_LIST[`${addr}`];
  if (contract) {
    const contractName = contract?.contractName;
    // @ts-ignore
    return ABI[`${contractName}`] || null;
  } else {
    return null;
  }
}

export function getContractMigration(networkId: number = NETWORK_MIGRATION) {
  switch (networkId) {
    // case 5:
    //   return Object.values(CONTRACT_LIST).find(item => item?.contractName === 'MIGRATION');
    case 56:
      const smcNameBSC = smcMode === 'mainnet' ? 'BSC_MIGRATION' : 'DEV_BSC_MIGRATION';
      return Object.values(CONTRACT_LIST).find(item => item?.contractName === smcNameBSC);
    case 24:
      const smcNameKAI = smcMode === 'mainnet' ? 'KAI_MIGRATION' : 'DEV_KAI_MIGRATION';
      return Object.values(CONTRACT_LIST).find(item => item?.contractName === smcNameKAI);
    default:
      const smcName = smcMode === 'mainnet' ? 'BSC_MIGRATION' : 'DEV_BSC_MIGRATION';
      return Object.values(CONTRACT_LIST).find(item => item?.contractName === smcName);
  }
}

export function getContractExchange() {
  let smcName = smcMode === 'mainnet' ? CONTRACT_NAME.LUCKYBOX : CONTRACT_NAME.DEV_LUCKYBOX;
  return Object.values(CONTRACT_LIST).find(item => item?.contractName === smcName);
}

export function getContractClaim(networkId: number = NETWORK_CLAIM) {
  let smcName = smcMode === 'mainnet' ? 'KAI_CLAIM' : 'DEV_KAI_CLAIM';
  switch (networkId) {
    // case 5:
    //   return Object.values(CONTRACT_LIST).find(item => item?.contractName === 'CLAIM');
    case 24:
      return Object.values(CONTRACT_LIST).find(item => item?.contractName === smcName);
    // case 56:
    //   return Object.values(CONTRACT_LIST).find(item => item?.contractName === 'BSC_CLAIM');
    default:
      return Object.values(CONTRACT_LIST).find(item => item?.contractName === smcName);
  }
}

export function getContractByName(contractName: string) {
  // @ts-ignore
  const contract = Object.values(CONTRACT_LIST).find(item => item?.contractName === contractName) as IContract;
  return contract || null; // Trả về null nếu không tìm thấy
}

export function getContractByAddress(address: string) {
  // @ts-ignore
  // const contract = CONTRACT_LIST[`${address.toLowerCase()}`];
  // @ts-ignore
  const contract = Object.values(CONTRACT_LIST).find(item => item?.addr.toLowerCase() === address.toLowerCase()) as IContract;
  return contract || null; // Trả về null nếu không tìm thấy
}
export function sleep(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}
export function randomHexString() {
  const hexChars = '0123456789abcdef';
  let hexString = '0x';
  for (let i = 0; i < 40; i++) {
    const randomIndex = Math.floor(Math.random() * hexChars.length);
    const randomChar = hexChars[randomIndex];
    hexString += randomChar;
  }
  return hexString;
}
export function randomNumber(min: number, max: number) {
  const randomNumber = Math.floor(Math.random() * max);
  return randomNumber + min;
}
