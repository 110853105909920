import Vue from 'vue';
export const eventProcessing = new Vue({
  name: 'Processing'
});

export const eventModalWallet = new Vue({
  name: 'ModalWallet'
});

export const eventModalConnect = new Vue({
  name: 'ModalConnect'
});

export const eventModalResult = new Vue({
  name: 'ModalResult'
});
