import { GetterTree } from 'vuex';
import { ButtonState, IMigrationState } from './state';
import type { IDpetSpirit, IUser } from '~/libs/interfaces';
const getters: GetterTree<IMigrationState, any> = {
  // getListNFT(state) {
  //   return state.nftAvailable;
  // },
  getListNFT(state) {
    return state.nfts;
  },
  getListNFTSubmited(state) {
    return state.nftSubmited;
  },
  getListV1(state) {
    return state.listV1;
  },
  getListV2(state) {
    return state.listV2;
  },
  getIsReady(state) {
    return state.isReady;
  },
  getRangeTime(state) {
    return {
      from: state.timeStartMigration,
      to: state.timeEndMigration
    };
  },
  getRangeTimeClaim(state) {
    return {
      from: state.timeStartClaim,
      to: state.timeEndClaim
    };
  },
  getTotalCredit(state) {
    return state.totalCredit;
  },
  getClaimed(state) {
    return state.claimed;
  },
  getNFTMigrated(state) {
    return state.nftSubmited;
  },
  getV1Migrated(state) {
    return state.v1Migrated;
  },
  getV2Migrated(state) {
    return state.v2Migrated;
  },
  getSeason(state): number {
    return state.season;
  },
  getTxPending(state): string {
    return state.pendingTx;
  },
  getClaimedCount(state): number {
    return state.claimedCount;
  },
  getUserInfo(state) {
    return {
      claimed: state.claimed,
      claimedCount: state.claimedCount,
      totalCredit: state.totalCredit,
      totalCreditV1: state.totalCreditV1,
      totalCreditV2: state.totalCreditV2,
      totalCreditSpirit: state.totalCreditSpirit,
      creditAvailable: state.creditAvailable,
      spiritMigrated: state.spiritMigrated,
      pendingTx: state.pendingTx
    };
  },
  getSpirit(state): IDpetSpirit {
    return {
      level1: state.dpetSpirit[1] || 0,
      level2: state.dpetSpirit[2] || 0,
      level3: state.dpetSpirit[3] || 0,
      level4: state.dpetSpirit[4] || 0,
      level5: state.dpetSpirit[5] || 0
    };
  },
  getTotalSpirit(state): number {
    const total = state.dpetSpirit.reduce((acc, cur) => acc + cur, 0);
    return total;
  },
  getSpiritMigrated(state): number {
    return state.spiritMigrated;
  },
  getDataCSV(state): IUser[] {
    return state.dataCSV;
  }
};

export default getters;
