import { GetterTree } from 'vuex';
import { InterfaceWeb3 } from './state';

const getters: GetterTree<InterfaceWeb3, any> = {
  getWalletConnect() {
    // @ts-ignore
    return $nuxt.$web3;
  },
  getIsWalletConnect(state) {
    return state.isWalletConnect;
  },
  getWalletAddress(state) {
    if (state.isLogin) return state.account;
    return null;
  },
  getChainId(state) {
    return state.chainId;
  },
  getConnected(state) {
    return state.isLogin;
  }
};

export default getters;
