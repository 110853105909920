import { MutationTree } from 'vuex';
import { ButtonState, IMigrationState } from './state';
import { IPet } from '~/libs/interfaces';

const mutations: MutationTree<IMigrationState> = {
  setNftProfile(state, data) {
    // @ts-ignore
    const ids = new Set(state.nfts.map(d => d?.tokenId));
    // @ts-ignore
    const merged = [...state.nfts, ...data.filter(d => !ids.has(d?.tokenId))];
    // @ts-ignore
    state.nfts = merged;
  },

  setNftSubmited(state, data) {
    const { submitedArray, remainingArray } = state.nfts.reduce(
      ({ submitedArray, remainingArray }, item) => {
        if (data.some((mg: IPet) => mg.tokenId === parseInt(item.tokenId))) {
          submitedArray.push(item);
        } else {
          remainingArray.push(item);
        }
        return { submitedArray, remainingArray };
      },
      { submitedArray: [], remainingArray: [] }
    );
    state.nftSubmited = submitedArray;
    state.nfts = remainingArray;
  },

  setNftV1(state, data) {
    // @ts-ignore
    const ids = new Set(state.listV1.map(d => d?.tokenId));
    // @ts-ignore
    const merged = [...state.listV1, ...data.filter((d: any) => !ids.has(d?.tokenId))];
    // @ts-ignore
    state.listV1 = merged;
  },

  setNftV2(state, data) {
    // @ts-ignore
    const ids = new Set(state.listV2.map(d => d?.tokenId));
    // @ts-ignore
    const merged = [...state.listV2, ...data.filter((d: any) => !ids.has(d?.tokenId))];
    // @ts-ignore
    state.listV2 = merged;
  },
  setCursorNext(state, data) {
    state.nextCursor = data;
  },
  resetListNFT(state) {
    state.nfts = [];
    state.nftAvailable = [];
    state.nftSubmited = [];
    state.listV1 = [];
    state.listV2 = [];
    state.nextCursor = '';
  },
  setSeason(state, season) {
    state.season = season;
  },
  setIsReady(state, isReady) {
    state.isReady = isReady;
  },
  setTimeMigration(state, { from, to }: { from: number; to: number }) {
    state.timeStartMigration = from;
    state.timeEndMigration = to;
  },
  setTimeClaim(state, { from, to }: { from: number; to: number }) {
    state.timeStartClaim = from;
    state.timeEndClaim = to;
  },
  setClaimed(state, claimed) {
    state.claimed = claimed;
  },
  setAllMigrated(state, total) {
    state.allMigrated = total;
  },
  setV1Migrated(state, v1Migrated) {
    state.v1Migrated = v1Migrated;
  },
  setV2Migrated(state, v2Migrated) {
    state.v2Migrated = v2Migrated;
  },
  setTotalCredit(state, total) {
    state.totalCredit = total;
  },
  setNFTMigrated(state, data) {
    state.nftSubmited = data;
  },
  setStorage(state, data) {
    state.storage = data;
  },
  setTxPending(state, txPending) {
    state.pendingTx = txPending;
  },
  setClaimedCount(state, claimedCount) {
    state.claimedCount = claimedCount;
  },
  setInfoUser(state, payload) {
    const { claimed, pending, storage, setClaimedCount } = payload;
    state.claimed = claimed;
    state.pendingTx = pending;
    state.storage = storage;
    state.claimedCount = setClaimedCount;
  },
  setInfoTotal(state, payload) {
    const { claimed, totalCredit, totalCreditV1, totalCreditV2, totalCreditSpirit, spiritMigrated, totalPetV1, totalPetV2 } = payload;
    state.totalCredit = totalCredit;
    state.totalCreditV1 = totalCreditV1;
    state.totalCreditV2 = totalCreditV2;
    state.totalCreditSpirit = totalCreditSpirit;
    const a = totalCredit - claimed;
    state.creditAvailable = a < 0 ? 0 : a;
    state.spiritMigrated = spiritMigrated;
    state.v1Migrated = totalPetV1;
    state.v2Migrated = totalPetV2;
  },
  setDpetSpirit(state, dpetSpirit) {
    state.dpetSpirit = dpetSpirit;
  },
  setDataCSV(state, dataCSV) {
    state.dataCSV = dataCSV;
  }
};

export default mutations;
