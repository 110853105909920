import { INetWork, IContract } from './interfaces';

export const NETWORKS: Record<string, INetWork> = require('~/configs/networks.json');
export const CONTRACT_LIST: Record<string, IContract> = require('~/configs/contactList.json');
const ABI_BSC_PET_V1: object[] = require('~/configs/abis/bsc-pet-v1.json');
const ABI_BSC_PET_V2: object[] = require('~/configs/abis/bsc-pet-v2.json');
const ABI_KAI_PET_V1: object[] = require('~/configs/abis/kai-pet-v1.json');
const ABI_KAI_PET_V2: object[] = require('~/configs/abis/kai-pet-v2.json');
const ABI_FAKE_PET_V1: object[] = require('~/configs/abis/fake-pet-v1.json');
const ABI_FAKE_PET_V2: object[] = require('~/configs/abis/fake-pet-v2.json');
const ABI_MIGRATION: object[] = require('~/configs/abis/migration.json');
const ABI_CLAIM: object[] = require('~/configs/abis/RecyclingCore.json');
const ABI_LUCKYBOX: object[] = require('~/configs/abis/luckybox.json');

export const ABI: Record<string, object[]> = {
  BSC_PET_V1: ABI_BSC_PET_V1,
  BSC_PET_V2: ABI_BSC_PET_V2,
  KAI_PET_V1: ABI_KAI_PET_V1,
  KAI_PET_V2: ABI_KAI_PET_V2,
  FAKE_PET_V1: ABI_FAKE_PET_V1,
  FAKE_PET_V2: ABI_FAKE_PET_V2,
  MIGRATION: ABI_MIGRATION,
  CLAIM: ABI_CLAIM,
  BSC_MIGRATION: ABI_MIGRATION,
  KAI_MIGRATION: ABI_MIGRATION,
  BSC_CLAIM: ABI_CLAIM,
  KAI_CLAIM: ABI_CLAIM,
  DEV_KAI_CLAIM: ABI_CLAIM,
  DEV_LUCKYBOX: ABI_LUCKYBOX,
  LUCKYBOX: ABI_LUCKYBOX
};

// const BSC_CLAIM = {
//   '0xea9cf0222f1c775ed7bd05518b5fb0ea5456ea88': {
//     contractName: 'BSC_CLAIM',
//     network: 56,
//     addr: '0xea9cf0222f1c775ed7bd05518b5fb0ea5456ea88'
//   }
// };

export const isDevelopment = process.env.NODE_ENV === 'development';

export const smcMode: string = process.env.SMC_MODE?.toString() as string;
export const supportChains: Record<string, INetWork[]> = {
  testnet: [NETWORKS['56'], NETWORKS['24']],
  mainnet: [NETWORKS['56'], NETWORKS['24']]
};

export const NETWORK_CLAIM = 24;
export const NETWORK_MIGRATION = 56;
export const NETWORK_LUCKYBOX = 24;

const ENDPOINT_MIGRATION: string = process.env.ENDPOINT_MIGRATION?.toString() as string;
const API_SCAN_DPET: string = process.env.API_SCAN_DPET?.toString() as string;
export const configApi = {
  getDpetNFT: API_SCAN_DPET,
  migration: ENDPOINT_MIGRATION
};
