import { ActionTree } from 'vuex';
import Web3 from 'web3';
import { InterfaceWeb3, initialState } from './state';
import {
  shortenWallet,
  w3FromWei,
  w3toWei,
  getRPCs,
  getConfigChain,
  isChainSupport,
  showLog,
  getChainSupport,
  getAbiByContract,
  getContractByName,
  getContractByAddress,
  getContractMigration,
  getContractClaim
} from '~/libs/helper';
import { NETWORK_CLAIM } from '~/libs/config';
import { ALLOWANCE_MAX, MAX_GAS_FEE, WEB3_TIME_LIMIT, STORAGE_WC_KEY, WALLET_TYPE } from '~/libs/const';
import { INetWork, IContract, Unit } from '~/libs/interfaces';
interface IConnect {
  clearCachedProvider: boolean;
  isWalletConnect: boolean;
}
const actions: ActionTree<InterfaceWeb3, any> = {
  async web3Connect({ state, commit, dispatch, getters }, { clearCachedProvider, isWalletConnect }: IConnect) {
    showLog('__________web3Connect___________');
    const wc = await getters.getWalletConnect;
    const web3 = await wc.connect(
      clearCachedProvider,
      isWalletConnect,
      (chainId: string) => dispatch('onChainChanged', chainId),
      (accounts: string[]) => dispatch('onAccountsChanged', accounts)
    );
    if (!web3) return null;
    const chainId: number = await wc.getChainId();
    const isCheck = await dispatch('checkNetWorkSupport', chainId);

    if (isCheck) {
      const account = await wc.getAccount();
      if (account) {
        commit('setAccount', account);
        commit('setChainId', chainId);
      }
      wc.removeModal();
      return web3;
    } else {
      // dispatch('disconnect');
      return null;
    }
  },

  async onChainChanged({ commit, dispatch, getters, state }, chainId: string) {
    const numberChain = parseInt(chainId, 16);
    if (!dispatch('checkNetWorkSupport', numberChain)) {
      dispatch('disconnect');
    } else {
      commit('setChainId', numberChain);
    }
  },

  onAccountsChanged({ commit, dispatch }, accounts: string[]) {
    showLog('onAccountsChanged', accounts);
    if (accounts.length > 0) commit('setAccount', accounts[0]);
    else dispatch('disconnect');
  },

  connect({ state, commit, dispatch, getters }, clearCachedProvider: boolean = false, isWalletConnect: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (clearCachedProvider) {
          await dispatch('disconnect');
        }
        const wc = getters.getWalletConnect;
        const cacheConnect = window.localStorage.getItem(STORAGE_WC_KEY);
        if (cacheConnect === WALLET_TYPE.walletconnect) isWalletConnect = true;

        if (wc.isConnected || cacheConnect) {
          const web3 = await dispatch('web3Connect', {
            clearCachedProvider: false,
            isWalletConnect
          });
          resolve(web3);
        } else {
          // @ts-ignore
          const isMobile = this.$device.isMobile;
          const isMetamask = window.ethereum !== undefined;
          if (isMobile) {
            if (isMetamask) {
              const web3 = await dispatch('web3Connect', {
                clearCachedProvider: true,
                isWalletConnect: false
              });
              if (web3) resolve(web3);
              // else resolve(null);
            } else {
              const web3 = await dispatch('web3Connect', {
                clearCachedProvider: true,
                isWalletConnect: true
              });
              if (web3) resolve(web3);
              // else resolve(null);
            }
            resolve(null);
          } else {
            // @ts-ignore
            this.$modalConnect.show({
              onResolve: async (web3: any) => {
                resolve(web3);
              },
              onReject: () => resolve(null)
            });
          }
        }
      } catch (error) {
        showLog('connect error', error);
        resolve(null);
      }
    });
  },

  async disconnect({ getters, commit }): Promise<void> {
    const web3haldle = await getters.getWalletConnect;
    web3haldle.disconnect();
    commit('resetState');
  },

  checkMetamaskUnlock({dispatch, getters}){
    return new Promise<boolean>( async (resolve) => {
      try {
        const wc = await getters.getWalletConnect;
        if (!wc.isWalletConnect) {
          const provider = wc.getWeb3Provider();
          const web3 = wc.getWeb3();
          console.log('provider', provider)
          console.log('web3', web3)
          resolve(true);
        } else {
          resolve(true);
        }
      } catch (error) {
        resolve(false);
      }
    })
  },

  checkNetWorkSupport({ dispatch }, chainId: number) {
    return new Promise<boolean>((resolve, reject) => {
      try {
        if (!isChainSupport(chainId)) {
          const supports = getChainSupport();
          // @ts-ignore
          this.$dialogMsg.show({
            popupType: 'switch_chain',
            title: 'Notification !!!',
            message: `We do not yet support the current network in your wallet. Please switch network: ${supports.map((id: number) => {
              const network = getConfigChain(id);
              return ' \n' + network?.chainName;
            })}`,
            persistent: true,
            buttons: [
              {
                btnText: 'Switch Network',
                btnCall: async () => {
                  resolve(await dispatch('metamaskSwitchChain', supports[0]));
                },
                isPrimary: true
              },
              {
                btnText: 'Cancel',
                btnCall: () => {
                  dispatch('disconnect');
                  resolve(false);
                }
              }
            ]
          });
        } else {
          resolve(true);
        }
      } catch (error) {
        showLog('checkNetWorkSupport', error);
        resolve(false);
      }
    });
  },

  checkNetWorkClaim({ state, dispatch }) {
    return new Promise<boolean>((resolve, reject) => {
      try {
        const chainId: number = state.chainId;
        if (chainId !== NETWORK_CLAIM) {
          const network = getConfigChain(NETWORK_CLAIM);
          // @ts-ignore
          this.$dialogMsg.show({
            popupType: 'switch_chain',
            title: 'Notification !!!',
            message: `We do not yet support the current network in your wallet. Please switch network: ${network?.chainName}`,
            persistent: true,
            buttons: [
              {
                btnText: 'Switch Network',
                btnCall: async () => {
                  resolve(await dispatch('metamaskSwitchChain', NETWORK_CLAIM));
                },
                isPrimary: true
              },
              {
                btnText: 'Cancel',
                btnCall: () => resolve(false)
              }
            ]
          });
        } else {
          resolve(true);
        }
      } catch (error) {
        showLog('checkNetWorkSupport', error);
        resolve(false);
      }
    });
  },

  async metamaskSwitchChain({ getters, state, dispatch }, networkId) {
    return new Promise(async resolve => {
      try {
        const wc = await getters.getWalletConnect;
        if (wc.isWalletConnect) {
          resolve(false);
        } else {
          // @ts-ignore
          this.$processing.start(`Please switch network to continue`);
          const provider = wc.getWeb3Provider();
          const chainId = `0x${networkId.toString(16)}`;
          await provider
            .request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId }]
            })
            .then(() => {
              setTimeout(() => {
                resolve(true);
              }, 500);
            })
            .catch(async (switchError: any) => {
              if (switchError?.code === 4902 || switchError.message.includes('wallet_addEthereumChain')) {
                resolve(await dispatch('metamaskAddNetWork'));
              } else {
                resolve(false);
              }
            });
          // @ts-ignore
          this.$processing.finish();
        }
      } catch (error: any) {
        // @ts-ignore
        this.$processing.finish();
        resolve(false);
      }
    });
  },

  metamaskAddNetWork({ getters }, networkId: number) {
    return new Promise(async resolve => {
      try {
        const wc = await getters.getWalletConnect;
        const provider = wc.getWeb3Provider();
        const chainId = `0x${networkId.toString(16)}`;
        // @ts-ignore
        const chain = this.$helper.getConfigChain(networkId);
        const addChain = {
          chainId: chainId,
          chainName: chain.chainName,
          nativeCurrency: { name: chain.tokenName, symbol: chain.tokenName, decimals: chain.decimals },
          rpcUrls: [chain.rpcUrl],
          blockExplorerUrls: [chain.explorer]
        };
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [addChain]
        });
        setTimeout(() => {
          resolve(true);
        }, 500);
      } catch (error) {
        resolve(false);
      }
    });
  },

  async getSignature({ getters, dispatch }, dataString: string = ''): Promise<void> {
    const web3haldle = await getters.getWalletConnect;

    // const network = getConfigChain();
    // const extraText = `.\n If you have waited too long, check and switch network your wallet to ${network.chainName}`;

    // @ts-ignore
    this.$processing.start(`You have a request that needs to be signed to complete`);
    const signature = await web3haldle.personalSign(dataString);
    return signature;
  },

  getInstanceContract({ state, getters, dispatch }, addrContract: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const abi = getAbiByContract(addrContract);
        const config = getContractByAddress(addrContract);
        const web3haldle = await getters.getWalletConnect;
        let web3;
        if (!state.isLogin) {
          const network = getConfigChain(config?.network as number);
          web3 = new Web3(new Web3.providers.HttpProvider(network.rpcUrl));
        } else {
          const chainId = state.chainId;
          if (chainId !== config.network) {
            const switchChain = await dispatch('metamaskSwitchChain', config.network);
            if (!switchChain) reject({});
          }
          web3 = web3haldle.getWeb3();
          if(!web3){
            web3 = await dispatch('connect');
          }
        }
        const contract = new web3.eth.Contract(abi, addrContract);
        resolve(contract);
      } catch (error) {
        reject(error);
      }
    });
  },
  approveToken({ state, dispatch, getters }, { tokenAddress, tokenId, exchangeAdress }) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        let isApproved = false;
        let addrApproved = '';
        const smc = getContractByAddress(tokenAddress);
        const web3haldle = await getters.getWalletConnect;
        let web3 = web3haldle.getWeb3();
        if (!state.isLogin) web3 = await dispatch('connect');
        if (web3) {
          // @ts-ignore
          this.$processing.start(`First you will need to allow access to and transfer of your token`);
          const contract: any = await dispatch('getInstanceContract', tokenAddress);

          if (smc.contractName.endsWith('PET_V1')) {
            addrApproved = await contract.methods.PetIndexToApproved(tokenId).call();
            isApproved = addrApproved.toLowerCase() === exchangeAdress.toLowerCase();
          } else if (smc.contractName.endsWith('PET_V2')) {
            addrApproved = await contract.methods.getApproved(tokenId).call();
            isApproved = addrApproved.toLowerCase() === exchangeAdress.toLowerCase();
          }
          if (isApproved) {
            // @ts-ignore
            this.$processing.finish();
            resolve(isApproved);
          } else {
            const approve = await contract.methods.approve(exchangeAdress, tokenId).send({ from: state.account, gas: MAX_GAS_FEE });
            // @ts-ignore
            this.$processing.finish();
            resolve(approve?.status);
          }
        } else {
          resolve(false);
        }
      } catch (error: any) {
        if (error?.code === -32603) {
          // @ts-ignore
          $nuxt.$toast.error('Approval failed!!!\n Please change another RPC RPC in Metamask');
        }
        resolve(false);
      }
    });
  }
};
export default actions;
