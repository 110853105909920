import { ICardGacha, IEggs, IBoxGacha } from './interfaces';
export const MAX_GAS_FEE = process.env.MAX_GAS_FEE || 400000;
export const ALLOWANCE_MAX = process.env.ALLOWANCE_MAX?.toString();
export const WEB3_TIME_LIMIT = process.env.WEB3_TIME_LIMIT || 31536000;
export const WC_PROJECT_ID: string = process.env.WC_ID?.toString() || '1584f6cce01e488dc9e8cdb557ee466c';
export const SMALL_WIDTH = 960;
import metaPages from '@/meta-seo.json';
export const META_PAGES = metaPages;
export const CONTRACT_NAME = {
  MIGRATION: 'MIGRATION',
  BSC_MIGRATION: 'BSC_MIGRATION',
  KAI_MIGRATION: 'KAI_MIGRATION',
  BSC_PET_V1: 'BSC_PET_V1',
  BSC_PET_V2: 'BSC_PET_V2',
  KAI_PET_V1: 'KAI_PET_V1',
  KAI_PET_V2: 'KAI_PET_V2',
  FAKE_PET_V1: 'FAKE_PET_V1',
  FAKE_PET_V2: 'FAKE_PET_V2',
  CLAIM: 'CLAIM',
  BSC_CLAIM: 'BSC_CLAIM',
  KAI_CLAIM: 'KAI_CLAIM',
  DEV_KAI_CLAIM: 'DEV_KAI_CLAIM',
  DEV_LUCKYBOX: 'DEV_LUCKYBOX',
  LUCKYBOX: 'LUCKYBOX'
};
export const DPET_SPIRIT = 'DPET_SPIRIT';

export const STORAGE_WC_KEY = 'WALLET_CONNECT_TYPE';

export const WALLET_TYPE = {
  metamask: 'metamask',
  walletconnect: 'walletconnect'
};

export const ICON_CONNECT_WALLET = {
  metamask: require('@/assets/img/icons/icon-metamask.svg'),
  walletconnect: require('@/assets/img/icons/icon-walletconnect.png')
};

export const ICON_NETWORK: Record<string, any> = {
  bsc: require('@/assets/img/icons/bsc.svg'),
  kai: require('@/assets/img/icons/kai.svg'),
  eth: require('@/assets/img/icons/eth.svg')
};

export const EGGS: IEggs[] = [
  {
    rarity: 0,
    name: 'Missed',
    class: 'card-none',
    img: require('@/assets/luckybox/egg_none.png'),
    bg: require('@/assets/luckybox/panel_none.png')
  },
  {
    rarity: 1,
    name: 'Common',
    class: 'card-common',
    img: require('@/assets/luckybox/egg_common.png'),
    bg: require('@/assets/luckybox/panel_common.png')
  },
  {
    rarity: 2,
    name: 'Rare',
    class: 'card-rare',
    img: require('@/assets/luckybox/egg_rare.png'),
    bg: require('@/assets/luckybox/panel_rare.png')
  },
  {
    rarity: 3,
    name: 'Elite',
    class: 'card-elite',
    img: require('@/assets/luckybox/egg_elite.png'),
    bg: require('@/assets/luckybox/panel_elite.png')
  },
  {
    rarity: 4,
    name: 'Epic',
    class: 'card-epic',
    img: require('@/assets/luckybox/egg_epic.png'),
    bg: require('@/assets/luckybox/panel_epic.png')
  }
];

export const LUCKYBOX: IBoxGacha[] = [
  {
    id: 1,
    rarity: 1,
    name: 'Basic Chest',
    price: 1,
    class: 'chest-common',
    img: require('@/assets/luckybox/chest_1.png'),
    bg: require('@/assets/luckybox/panel_chest_1.png'),
    src_mp4: require('@/assets/video/chest_basic.mp4'),
    src_webm: require('@/assets/video/chest_basic.webm'),
    ratio: [
      { name: 'Miss', percent: 95 },
      { name: 'Common', percent: 5 },
      { name: 'Rare', percent: 0 },
      { name: 'Elite', percent: 0 },
      { name: 'Epic', percent: 0 }
    ]
  },
  {
    id: 2,
    rarity: 2,
    name: 'Standard Chest',
    price: 20,
    class: 'chest-rare',
    img: require('@/assets/luckybox/chest_2.png'),
    bg: require('@/assets/luckybox/panel_chest_2.png'),
    src_mp4: require('@/assets/video/chest_standard.mp4'),
    src_webm: require('@/assets/video/chest_standard.webm'),
    ratio: [
      { name: 'Miss', percent: 9 },
      { name: 'Common', percent: 90 },
      { name: 'Rare', percent: 1 },
      { name: 'Elite', percent: 0 },
      { name: 'Epic', percent: 0 }
    ]
  },
  {
    id: 3,
    rarity: 3,
    name: 'Enhance Chest',
    price: 100,
    class: 'chest-elite',
    img: require('@/assets/luckybox/chest_3.png'),
    bg: require('@/assets/luckybox/panel_chest_3.png'),
    src_mp4: require('@/assets/video/chest_enhance.mp4'),
    src_webm: require('@/assets/video/chest_enhance.webm'),
    ratio: [
      { name: 'Miss', percent: 0 },
      { name: 'Common', percent: 10 },
      { name: 'Rare', percent: 80 },
      { name: 'Elite', percent: 10 },
      { name: 'Epic', percent: 0 }
    ]
  },
  {
    id: 4,
    rarity: 4,
    name: 'Premium Chest',
    price: 500,
    class: 'chest-epic',
    img: require('@/assets/luckybox/chest_4.png'),
    bg: require('@/assets/luckybox/panel_chest_4.png'),
    src_mp4: require('@/assets/video/chest_premium.mp4'),
    src_webm: require('@/assets/video/chest_premium.webm'),
    ratio: [
      { name: 'Miss', percent: 0 },
      { name: 'Common', percent: 0 },
      { name: 'Rare', percent: 40 },
      { name: 'Elite', percent: 50 },
      { name: 'Epic', percent: 10 }
    ]
  }
];

export const BUY_ONE_TIME: ICardGacha[] = [
  {
    id: 1,
    rarity: EGGS[1].rarity,
    name: 'Common Egg',
    price: 1,
    class: EGGS[1].class,
    img: EGGS[1].img,
    bg: EGGS[1].bg
  },
  {
    id: 2,
    rarity: EGGS[2].rarity,
    name: 'Rare Egg',
    price: 100,
    class: EGGS[2].class,
    img: EGGS[2].img,
    bg: EGGS[2].bg
  },
  {
    id: 3,
    rarity: EGGS[3].rarity,
    name: 'Elite Egg',
    price: 500,
    class: EGGS[3].class,
    img: EGGS[3].img,
    bg: EGGS[3].bg
  },
  {
    id: 4,
    rarity: EGGS[4].rarity,
    name: 'Epic Egg',
    price: 2500,
    class: EGGS[4].class,
    img: EGGS[4].img,
    bg: EGGS[4].bg
  }
];
export const ICON_ELEMENTAL = [
  require('@/assets/luckybox/element_earth.png'),
  require('@/assets/luckybox/element_water.png'),
  require('@/assets/luckybox/element_wind.png'),
  require('@/assets/luckybox/element_fire.png')
];