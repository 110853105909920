import Web3 from 'web3';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { Plugin } from '@nuxt/types';
import { getRPCs, getConfigChain, getChainSupport, isChainSupport, showLog } from '~/libs/helper';
import { WALLET_TYPE, WC_PROJECT_ID, STORAGE_WC_KEY } from '~/libs/const';
declare var window: any;
declare var localStorage: any;
const defaultChain = getConfigChain();
const chainIds = getChainSupport();
chainIds.splice(0, 1);
const projectId = WC_PROJECT_ID;
const web3Plugin: Plugin = async (context, inject) => {
  const pluginInstance = new Web3Handler();
  await pluginInstance.init();
  inject('web3', pluginInstance);
};

class Web3Handler {
  private provider: any | null;
  private web3: any | null;
  private isConnected: boolean | false;
  private isWalletConnect: boolean | false;

  constructor() {
    this.web3 = null;
    this.provider = null;
    this.isConnected = false;
    this.isWalletConnect = false;
  }

  async init() {
    const walletType = localStorage.getItem(STORAGE_WC_KEY);
    switch (walletType) {
      case WALLET_TYPE.metamask:
        const providerEth: any = window.ethereum;
        this.isConnected = providerEth.isConnected();
        this.setDataWeb3(providerEth);
        break;
      case WALLET_TYPE.walletconnect:
        const provider: any = await this.getProvider(true);
        this.isConnected = provider.connected;
        this.setDataWeb3(provider);
        break;
      default:
        break;
    }
  }

  async getProvider(isWalletConnect: boolean = false) {
    return new Promise(async (resolve, reject) => {
      if (isWalletConnect) {
        const provider = await EthereumProvider.init({
          projectId, // REQUIRED your projectId
          chains: [defaultChain.chainId], // REQUIRED chain ids //defaultChain.chainId
          optionalChains: chainIds,
          showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal
          methods: ['eth_chainId', 'eth_sendTransaction', 'eth_signTransaction', 'eth_sign', 'personal_sign', 'eth_signTypedData', 'wallet_switchEthereumChain', 'wallet_addEthereumChain'], // REQUIRED ethereum methods
          // optionalMethods, // OPTIONAL ethereum methods
          events: ['chainChanged', 'accountsChanged'], // REQUIRED ethereum events
          // optionalEvents, // OPTIONAL ethereum events
          rpcMap: getRPCs, // OPTIONAL rpc urls for each chain
          // metadata, // OPTIONAL metadata of your app
          qrModalOptions: {
            themeMode: 'dark'
          } // OPTIONAL - `undefined` by default, see https://docs.walletconnect.com/2.0/web3modal/options
        });
        resolve(provider);
      } else {
        if (typeof window.ethereum !== 'undefined') {
          resolve(window.ethereum);
        }
      }
    });
  }

  setLocalStorage() {
    localStorage.removeItem(STORAGE_WC_KEY);
    if (this.isWalletConnect) localStorage.setItem(STORAGE_WC_KEY, WALLET_TYPE.walletconnect);
    else localStorage.setItem(STORAGE_WC_KEY, WALLET_TYPE.metamask);
  }

  clearLocalStorage() {
    localStorage.removeItem(STORAGE_WC_KEY);
    var keys = Object.keys(localStorage);
    keys.forEach(key => {
      if (key.startsWith('wc')) {
        localStorage.removeItem(key);
      }
    });
  }

  removeModal() {
    const styleModal: any = document.getElementById('wcm-styles');
    if (styleModal) styleModal.remove();
    const elModal: any = document.getElementsByTagName('wcm-modal');
    if (elModal.length > 0) {
      if (elModal.length > 0) {
        Array.from(elModal).forEach((item: any) => item.remove());
      }
    }
  }

  async setDataWeb3(provider: any) {
    const web3 = await new Web3(provider);
    this.web3 = web3;
    window.gaiaweb3 = web3;
    this.provider = provider;
  }

  async connect(clearCachedProvider: boolean = false, isWalletConnect: boolean = false, onChainChanged: (chainId: string) => void, onAccountsChanged: (accounts: string[]) => void) {
    this.isWalletConnect = isWalletConnect;
    let isConnected = this.isConnected;
    const pluginSubcribeChain = (chainId: string) => {
      if (this.isWalletConnect) {
        const provider = this.provider;
        provider?.setChainId(chainId);
        this.setDataWeb3(provider);
      }
      onChainChanged(chainId);
    };

    const pluginSubcribeAccount = (accounts: string[]) => {
      onAccountsChanged(accounts);
    };

    return new Promise(async (resolve, reject) => {
      try {
        this.removeModal();
        if (clearCachedProvider) {
          isConnected = false;
          this.disconnect();
        }
        if (isConnected) {
          const provider = this.provider;
          if (provider.removeListener) {
            provider.removeListener('chainChanged', pluginSubcribeChain);
            provider.removeListener('accountsChanged', pluginSubcribeAccount);
          }

          if (provider) {
            if (this.isWalletConnect) {
              if (!provider.events._events?.chainChanged) provider.on('chainChanged', pluginSubcribeChain);
              if (!provider.events._events?.accountsChanged) provider.on('accountsChanged', pluginSubcribeAccount);
            } else {
              if (!provider._events?.chainChanged) provider.on('chainChanged', pluginSubcribeChain);
              if (!provider._events?.accountsChanged) provider.on('accountsChanged', pluginSubcribeAccount);
            }
          }
          this.setDataWeb3(provider);
          resolve(this.getWeb3());
        } else {
          const provider: any = await this.getProvider(this.isWalletConnect);

          if (provider.removeListener) {
            provider.removeListener('chainChanged', pluginSubcribeChain);
            provider.removeListener('accountsChanged', pluginSubcribeAccount);
          }

          if (this.isWalletConnect) {
            provider?.modal.subscribeModal((modal: any) => {
              const { open } = modal;
              if (!open) {
                this.removeModal();
              }
            });
          }
          provider.on('chainChanged', pluginSubcribeChain);
          provider.on('accountsChanged', pluginSubcribeAccount);

          await provider.enable().then(async () => {
            this.setLocalStorage();
            this.isConnected = true;
            this.setDataWeb3(provider);
          });

          if (isWalletConnect) {
            setTimeout(() => {
              resolve(this.web3);
            }, 3000);
          } else {
            resolve(this.web3);
          }
        }
      } catch (error) {
        console.error('walletConnect ', error);
        resolve(null);
      }
    });
  }

  async disconnect() {
    if (this.provider?.removeListener) {
      if (this.isWalletConnect) {
        if (this.provider?.events._events) {
          const pluginSubcribeChain = this.provider.events._events?.chainChanged;
          this.provider.removeListener('chainChanged', pluginSubcribeChain);
          const pluginSubcribeAccount = this.provider.events._events?.accountsChanged;
          this.provider.removeListener('accountsChanged', pluginSubcribeAccount);
        }
      } else {
        showLog('this.provider', this.provider);
        if (this.provider?._events) {
          const pluginSubcribeChain = this.provider._events?.chainChanged;
          this.provider.removeListener('chainChanged', pluginSubcribeChain);
          const pluginSubcribeAccount = this.provider._events.accountsChanged;
          this.provider.removeListener('accountsChanged', pluginSubcribeAccount);
        }
      }
    }
    this.isConnected = false;
    this.provider = null;
    this.web3 = null;
    this.clearLocalStorage();
    this.removeModal();
  }

  isWalletConnected() {
    return this.isConnected;
  }

  getWeb3() {
    return this.web3;
  }

  getWeb3Provider() {
    return this.provider;
  }

  async getAccount() {
    if (this.isConnected) {
      const accounts: any[] = await this.web3.eth.getAccounts();
      return accounts[0];
    } else {
      return null;
    }
  }

  async getChainId() {
    if (this.isConnected) {
      const chainId: number = await this.web3.eth.getChainId();
      return chainId;
    } else {
      return 0;
    }
  }

  async personalSign(data: any) {
    try {
      if (this.isConnected) {
        const web3 = this.getWeb3();
        const address = await this.getAccount();
        const signature = await web3.eth.personal.sign(web3.utils.fromUtf8(data), address);
        return signature;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}
export default web3Plugin;
