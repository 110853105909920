import { ActionTree } from 'vuex';
import { ButtonState, IAppState } from './state';
const actions: ActionTree<IAppState, any> = {
  appLoading({ commit }, payload: { status: boolean }) {
    commit('setAppLoading', payload.status);
  },
  showModalWallet({ commit }) {
    commit('setOpenModalWallet', true);
  },
  hideModalWallet({ commit }) {
    commit('setOpenModalWallet', false);
  },
  loadingCredit({ commit }, isLoading: boolean) {
    commit('setLoadCredit', isLoading);
  }
};

export default actions;
