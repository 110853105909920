import { ActionTree } from 'vuex';
import Web3 from 'web3';
import { ICreditState } from './state';
import { CONTRACT_LIST, configApi, NETWORK_CLAIM, NETWORK_LUCKYBOX, smcMode } from '~/libs/config';
import { getConfigChain, getContractByAddress, getContractByName, getContractMigration, getContractClaim, getAbiByContract, w3FromWei, w3toWei } from '~/libs/helper';
import { CONTRACT_NAME, MAX_GAS_FEE, DPET_SPIRIT } from '~/libs/const';
import { IContract, ICardGacha } from '~/libs/interfaces';
const actions: ActionTree<ICreditState, any> = {
  checkIsReady({ commit, dispatch, rootState, rootGetters }) {
    return new Promise<boolean>(async resolve => {
      try {
        const smcName = smcMode === 'mainnet' ? CONTRACT_NAME.LUCKYBOX : CONTRACT_NAME.DEV_LUCKYBOX;
        const smc = getContractByName(smcName);
        // const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
        // const res: boolean = await contractInstance.methods.isReady().call();
        const abi = getAbiByContract(smc?.addr);
        const network = getConfigChain(smc?.network as number);
        const web3: any = new Web3(new Web3.providers.HttpProvider(network.rpcUrl));
        const contractInstance = new web3.eth.Contract(abi, smc?.addr);
        const res = await contractInstance.methods.isReady().call();
        commit('setIsReady', res);
        resolve(res);
      } catch (error) {
        resolve(false);
      }
    });
  },

  checkBuyOneTime({ commit, dispatch, rootState, rootGetters }, rarity: number) {
    return new Promise<boolean>(async resolve => {
      try {
        if (rootState.web3.isLogin) {
          const addrWallet = rootState.web3.account;
          const smcName = smcMode === 'mainnet' ? CONTRACT_NAME.LUCKYBOX : CONTRACT_NAME.DEV_LUCKYBOX;
          const smc = getContractByName(smcName);
          // const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
          // const res: boolean = await contractInstance.methods.isReady().call();
          const abi = getAbiByContract(smc?.addr);
          const network = getConfigChain(smc?.network as number);
          const web3: any = new Web3(new Web3.providers.HttpProvider(network.rpcUrl));
          const contractInstance = new web3.eth.Contract(abi, smc?.addr);
          const res: boolean = await contractInstance.methods.buyOneTime(addrWallet, rarity).call();
          console.log('checkBuyOneTime res', res);
          commit('setBuyOneTime', { type: rarity.toString(), result: res });
          resolve(res);
        } else {
          resolve(false);
        }
      } catch (error) {
        resolve(false);
      }
    });
  },
  async getBuyOneTime({ state, commit, dispatch, rootState, rootGetters }) {
    try {
      if (rootState.web3.isLogin) {
        const addrWallet = rootState.web3.account;
        const smcName = smcMode === 'mainnet' ? CONTRACT_NAME.LUCKYBOX : CONTRACT_NAME.DEV_LUCKYBOX;
        const smc = getContractByName(smcName);
        const abi = getAbiByContract(smc?.addr);
        const network = getConfigChain(smc?.network as number);
        const web3: any = new Web3(new Web3.providers.HttpProvider(network.rpcUrl));
        const contractInstance = new web3.eth.Contract(abi, smc?.addr);
        const res: boolean[] = await Promise.all([
          contractInstance.methods.buyOneTime(addrWallet, 1).call(), // common
          contractInstance.methods.buyOneTime(addrWallet, 2).call(), // rare
          contractInstance.methods.buyOneTime(addrWallet, 3).call(), // elite
          contractInstance.methods.buyOneTime(addrWallet, 4).call() // epic
        ]);
        commit('setAllBuyOneTime', res);
        // const res:boolean = await contractInstance.methods.buyOneTime(addrWallet, rarity).call();
        return res;
      }
    } catch (error) {
      console.log('getBuyOneTime error', error);
    }
  },
  async getTimeExchange({ state, commit, dispatch, rootState, rootGetters }) {
    try {
      const smcName = smcMode === 'mainnet' ? CONTRACT_NAME.LUCKYBOX : CONTRACT_NAME.DEV_LUCKYBOX;
      const smc = getContractByName(smcName);
      if (smc && smc !== undefined) {
        const abi = getAbiByContract(smc?.addr);
        const network = getConfigChain(smc?.network as number);
        const web3: any = new Web3(new Web3.providers.HttpProvider(network.rpcUrl));
        const contractInstance = new web3.eth.Contract(abi, smc?.addr);
        // const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
        const rangeTimeClaim = await contractInstance.methods.getTime().call();
        commit('setTimeExchange', { from: parseInt(rangeTimeClaim[0]), to: parseInt(rangeTimeClaim[1]) });
        return rangeTimeClaim;
      }
    } catch (error) {
      return [0, 0];
    }
  },
  async getBalanceCredit({ state, commit, dispatch, rootState, rootGetters }) {
    try {
      if (rootState.web3.isLogin) {
        const addrWallet = rootState.web3.account;
        const smcName = smcMode === 'mainnet' ? CONTRACT_NAME.KAI_CLAIM : CONTRACT_NAME.DEV_KAI_CLAIM;
        const smc = getContractByName(smcName);
        const abi = getAbiByContract(smc?.addr);
        const network = getConfigChain(smc?.network as number);
        const web3: any = new Web3(new Web3.providers.HttpProvider(network.rpcUrl));
        const contractInstance = new web3.eth.Contract(abi, smc?.addr);
        // const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
        const res = await contractInstance.methods.accounts(addrWallet).call();
        commit('setAccountsCredit', { balanceOf: w3FromWei(res.balances), used: parseInt(res.use) });
        return { balanceOf: w3FromWei(res.balances), used: parseInt(res.use) };
        // commit('setAccountsCredit', { balanceOf: parseInt(res.balances), used: parseInt(res.use) });
        // return { balanceOf: parseInt(res.balances), used: parseInt(res.use) };
      } else {
        commit('setAccountsCredit', { balanceCredit: 0, used: 0 });
        return { balanceOf: 0, used: 0 };
      }
    } catch (error) {
      console.log('getBalanceCredit error:', error);
      return { balanceOf: 0, used: 0 };
    }
  },

  buypack({ state, getters, commit, dispatch, rootState }, rarity: number) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!rootState.web3.isLogin) await dispatch('web3/connect', null, { root: true });
        const account = rootState.web3.account;
        const checkNetwork: boolean = await dispatch('web3/checkNetWorkClaim', {}, { root: true });
        const network = getConfigChain(NETWORK_LUCKYBOX);
        if (!checkNetwork) reject({ message: `Network not supported, please switch ${network?.chainName}` });
        const smcName = smcMode === 'mainnet' ? CONTRACT_NAME.LUCKYBOX : CONTRACT_NAME.DEV_LUCKYBOX;
        const smc = getContractByName(smcName);
        // const smc = getContractMigration(chainId) as IContract;
        const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
        const res = await contractInstance.methods.buyPack(rarity).send({
          from: account,
          gas: MAX_GAS_FEE
        });
        if (res.status) {
          const resEvent = res.events.BuyPack;
          const { transactionHash, returnValues } = resEvent;
          commit('setTransBuyPack', resEvent);
          resolve({ success: true, returnValues, transactionHash });
        } else {
          reject({ message: 'Transaction Failed' });
        }
        resolve(res);
      } catch (error:any) {
        console.log('buypack error', error);
        if(error?.code === 4100) error.message = `Please unlock your wallet account`
        reject(error);
      }
    });
  },
  eventsBuyPack({ commit, dispatch, state, rootState }, blockFrom) {
    return new Promise(async (resolve, reject) => {
      try {
        const address = rootState.web3.account;
        const fromBlock = blockFrom;
        const toBlock = 'latest';
        const options = {
          filter: { from: address },
          fromBlock,
          toBlock
        };

        const smcName = smcMode === 'mainnet' ? CONTRACT_NAME.LUCKYBOX : CONTRACT_NAME.DEV_LUCKYBOX;
        const smc = getContractByName(smcName);
        const abi = getAbiByContract(smc?.addr);
        const network = getConfigChain(smc?.network as number);
        const web3: any = new Web3(new Web3.providers.HttpProvider(network.rpcUrl));
        const contractInstance = new web3.eth.Contract(abi, smc?.addr);
        const res = await contractInstance.getPastEvents('BuyPack', options);
        // resolve(res);
        if (res && res !== undefined) {
          if (res.length > 0 && res[0]?.event === 'BuyPack') {
            const resEvent = res[0];
            commit('setTransBuyPack', resEvent);
            const { transactionHash, returnValues } = resEvent;
            resolve({ success: true, returnValues, transactionHash });
          } else {
            resolve({ success: false, returnValues: null });
          }
        }
      } catch (e) {
        console.log('eventsBuyPack error:', e);
        reject(e);
      }
    });
  },

  openBox({ state, getters, commit, dispatch, rootState }, { type, qty }: { type: number; qty: number }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!rootState.web3.isLogin) await dispatch('web3/connect', null, { root: true });
        const limitGas = parseInt(MAX_GAS_FEE.toString());
        let gas: number = limitGas;
        if (qty > 1) gas = limitGas * qty;
        const account = rootState.web3.account;
        const checkNetwork: boolean = await dispatch('web3/checkNetWorkClaim', {}, { root: true });
        const network = getConfigChain(NETWORK_LUCKYBOX);
        if (!checkNetwork) reject({ message: `Network not supported, please switch ${network?.chainName}` });
        const smcName = smcMode === 'mainnet' ? CONTRACT_NAME.LUCKYBOX : CONTRACT_NAME.DEV_LUCKYBOX;
        const smc = getContractByName(smcName);
        // const smc = getContractMigration(chainId) as IContract;
        const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
        const res = await contractInstance.methods.openBox(type, qty).send({
          from: account,
          gas
        });
        console.log('open res', res)
        if (res.status && res?.events.OpenBox) {
          const resEvent = res.events.OpenBox;
          const { transactionHash } = res;
          if (!Array.isArray(resEvent)) resolve({ success: true, dataEvent: [resEvent], transactionHash, isSingle: true });
          else resolve({ success: true, dataEvent: resEvent, transactionHash, isSingle: false });
        } else {
          reject({ message: 'Transaction Failed' });
        }
      } catch (error:any) {
        if(error?.code === 4100) error.message = `Please unlock your wallet account`
        reject(error);
      }
    });
  }
};
export default actions;
