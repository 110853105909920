import { Context } from '@nuxt/types';
export default ({ app }: Context) => {
  if (process.client && process.env.NODE_ENV === 'production') {
    document.addEventListener('contextmenu', e => {
      e.preventDefault();
    });
    document.addEventListener('keydown', e => {
      if (e.key === 'F12') {
        e.preventDefault();
      }
    });

    document.addEventListener('keydown', event => {
      if ((event.metaKey || event.ctrlKey) && event.altKey && event.keyCode === 73) {
        event.preventDefault();
      }
    });
  }
};
