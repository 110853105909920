import type { IUser } from '~/libs/interfaces';
export interface ButtonState {
  title: string;
  action: any; //0: close, 2: Wallet Info, string: route
}
export interface IMigrationState {
  nfts: any[];
  nftAvailable: any[];
  nftSubmited: any[];
  listV1: any[];
  listV2: any[];
  nextCursor: string;
  season: number;
  isReady: boolean;
  timeStartMigration: number;
  timeEndMigration: number;
  timeStartClaim: number;
  timeEndClaim: number;
  allMigrated: number;
  v1Migrated: number;
  v2Migrated: number;
  claimed: number;
  totalCredit: number;
  storage: object[];
  claimedCount: number;
  pendingTx: string | '';
  totalCreditV1: number;
  totalCreditV2: number;
  totalCreditSpirit: number;
  creditAvailable: number;
  dpetSpirit: number[];
  spiritMigrated: number;
  dataCSV: IUser[];
}

const state = (): IMigrationState => ({
  nfts: [],
  nftAvailable: [],
  nftSubmited: [],
  nextCursor: '',
  listV1: [],
  listV2: [],
  season: 1,
  isReady: false,
  timeStartMigration: 0,
  timeEndMigration: 0,
  allMigrated: 0,
  v1Migrated: 0,
  v2Migrated: 0,
  claimed: 0,
  totalCredit: 0,
  storage: [],
  claimedCount: 0,
  pendingTx: '',
  totalCreditV1: 0,
  totalCreditV2: 0,
  totalCreditSpirit: 0,
  creditAvailable: 0,
  dpetSpirit: [0, 0, 0, 0, 0],
  spiritMigrated: 0,
  timeStartClaim: 0,
  timeEndClaim: 0,
  dataCSV: []
});

export default state;
