//@ts-ignore
import shareMutations from 'vuex-shared-mutations';

export default ({ store }: any) => {
  //@ts-ignore
  window.onNuxtReady(nuxt => {
    shareMutations({
      predicate: ['web3/setAccount', 'web3/setChainId']
    })(store);
  });
};
