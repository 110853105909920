import Vue from 'vue';

export interface Button {
  btnText: string;
  btnCall: any;
  isPrimary: boolean;
}
export interface IPopupMessage {
  popupType: string;
  title: string;
  message: string;
  persistent: boolean;
  buttons: Button[];
}

export const eventDialogMsg = new Vue({
  name: 'DialogMessage'
});
