export interface ICreditState {
  isReady: boolean;
  timeStartExchange: number;
  timeEndExchange: number;
  buyOneTime: {
    '1': boolean | false;
    '2': boolean | false;
    '3': boolean | false;
    '4': boolean | false;
  };
  balanceCredit: number;
  usedCredit: number;
  transBuyPack: string;
  transOpenBox: string;
}

const state = (): ICreditState => ({
  isReady: false,
  timeStartExchange: 0,
  timeEndExchange: 0,
  buyOneTime: {
    '1': false,
    '2': false,
    '3': false,
    '4': false
  },
  balanceCredit: 0,
  usedCredit: 0,
  transBuyPack: '',
  transOpenBox: ''
});
export default state;
