import { MutationTree } from 'vuex';
import { ICreditState } from './state';
import { ICardGacha } from '~/libs/interfaces';

const mutations: MutationTree<ICreditState> = {
  setIsReady(state, isReady) {
    state.isReady = isReady;
  },
  setTimeExchange(state, { from, to }: { from: number; to: number }) {
    state.timeStartExchange = from;
    state.timeEndExchange = to;
  },
  setAccountsCredit(state, { balanceOf, used }: { balanceOf: number; used: number }) {
    state.balanceCredit = balanceOf;
    state.usedCredit = used;
  },
  setBuyOneTime(state, { type, result }: { type: string; result: boolean }) {
    let buyOneTime = state.buyOneTime;
    // @ts-ignore
    buyOneTime[`${type}`] = result;
    console.log('buyOneTime', buyOneTime);
    state.buyOneTime = buyOneTime;
  },
  setAllBuyOneTime(state, arrRes: boolean[]) {
    let buyOneTime = state.buyOneTime;
    // @ts-ignore
    buyOneTime[`1`] = arrRes[0];
    buyOneTime[`2`] = arrRes[1];
    buyOneTime[`3`] = arrRes[2];
    buyOneTime[`4`] = arrRes[3];
    state.buyOneTime = buyOneTime;
  },
  setTransBuyPack(state, transBuyPack) {
    state.transBuyPack = transBuyPack;
  },
  setTransOpenBox(state, transOpenBox) {
    state.transOpenBox = transOpenBox;
  }
};

export default mutations;
