import { GetterTree } from 'vuex';
import { ICreditState } from './state';
import { ICardGacha } from '~/libs/interfaces';
const getters: GetterTree<ICreditState, any> = {
  getIsReady(state) {
    return state.isReady;
  },
  getRangeTime(state) {
    return {
      from: state.timeStartExchange,
      to: state.timeEndExchange
    };
  },
  getBalanceOf(state) {
    return state.balanceCredit;
  },
  getBuyOneTime(state) {
    return state.buyOneTime;
  }
};

export default getters;
