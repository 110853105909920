import { GetterTree } from 'vuex';
import { IAppState } from './state';
const getters: GetterTree<IAppState, any> = {
  isAppLoading(state): boolean {
    return state.isAppLoading;
  },
  isOpenModalWallet(state): boolean {
    return state.isOpenModalWallet;
  },
  isLoadCredit(state) {
    return state.isLoadCredit;
  }
};

export default getters;
