import { ActionTree } from 'vuex';
import Web3 from 'web3';
import { ButtonState, IMigrationState } from './state';
import { CONTRACT_LIST, configApi, NETWORK_CLAIM } from '~/libs/config';
import { getConfigChain, getContractByAddress, getContractByName, getContractMigration, getContractClaim, getAbiByContract } from '~/libs/helper';
import { CONTRACT_NAME, MAX_GAS_FEE, DPET_SPIRIT } from '~/libs/const';
import { IContract, IPet, IResultStorage, IUser } from '~/libs/interfaces';
//0x3Ff1E44339381B0cafee6c69d8f2A5EB50873a9f bsc
// 0x4E26738f6A88D9E48014cDB0121270a183C460C2 kai

const actions: ActionTree<IMigrationState, any> = {
  resetListNFT({ commit }) {
    commit('resetListNFT');
  },

  // async getDpetNFTs({ state, dispatch, commit, rootState }) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const isConnected = rootState.web3.isLogin;
  //       if (isConnected) {
  //         const account = rootState.web3.account;
  //         const contract = getContractByName(CONTRACT_NAME.FAKE_PET_V2) as IContract;
  //         const contractInstance = await dispatch('web3/getInstanceContract', contract.addr, { root: true });
  //         const balance = await contractInstance.methods.balanceOf(account).call();

  //         // @ts-ignore
  //         let data: IPet[] = [];
  //         if (parseInt(balance) > 0) {
  //           for (let i = 0; i < balance; i++) {
  //             const tokenId = await contractInstance.methods.tokenOfOwnerByIndex(account, i).call();
  //             data.push({
  //               tokenId,
  //               name: `Pet #${tokenId}`,
  //               image: ``,
  //               version: 2,
  //               chain: 'eth',
  //               addr: contract.addr
  //             });
  //           }
  //           commit('setNftProfile', data);
  //         } else {
  //           commit('resetListNFT');
  //         }
  //       }
  //       resolve({ success: true });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },

  async getDpetNFTs({ state, dispatch, commit, rootState }) {
    return new Promise(async (resolve, reject) => {
      try {
        const isConnected = rootState.web3.isLogin;
        if (isConnected) {
          const chainId = rootState.web3.chainId;
          const address = rootState.web3.account;
          // const address = '0x3Ff1E44339381B0cafee6c69d8f2A5EB50873a9f'; //bsc
          // const address = '0x9De88D5745A04DfCd07dbB3e120dd928f8afaA85'; //kai
          const network = getConfigChain(chainId);
          const chain = network?.chainIcon || 'bsc';
          const data = await dispatch('fetchPET', {
            address,
            chainName: chain,
            cursor: state.nextCursor
          });
          commit('setNftProfile', data);
          if (data.length > 49) {
            await dispatch('getDpetNFTs');
          } else {
            await dispatch('getSubmited');
          }
        } else {
          commit('resetListNFT');
        }
        resolve({ success: true });
      } catch (error) {
        reject(error);
      }
    });
  },

  async fetchPET({ state, dispatch, commit }, { address, cursor, chainName }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${configApi.getDpetNFT}/${address.toLocaleLowerCase()}`, {
          chain: chainName,
          mini: true,
          cursor
        })
        .then(async res => {
          if (res?.data.length > 49) {
            commit('setCursorNext', res?.nextCursor);
          } else {
            commit('setCursorNext', '');
          }
          const data = await Promise.all(
            res?.data.map((item: { tokenId: number; version: number }) => {
              const { tokenId, version } = item;
              let addr;
              if (chainName === 'bsc') {
                if (version === 1) {
                  const contract = getContractByName(CONTRACT_NAME.BSC_PET_V1) as IContract;
                  addr = contract.addr;
                } else {
                  const contract = getContractByName(CONTRACT_NAME.BSC_PET_V2) as IContract;
                  addr = contract.addr;
                }
              }
              if (chainName === 'kai') {
                if (version === 1) {
                  const contract = getContractByName(CONTRACT_NAME.KAI_PET_V1) as IContract;
                  addr = contract.addr;
                } else {
                  const contract = getContractByName(CONTRACT_NAME.KAI_PET_V2) as IContract;
                  addr = contract.addr;
                }
              }
              return dispatch('parseDetailPet', { tokenId, chainName, addr, version });
            })
          );
          resolve(data);
        })
        .catch(error => resolve([]));
    });
  },

  ////////New
  checkIsReady({ commit, dispatch, rootState, rootGetters }) {
    return new Promise<boolean>(async resolve => {
      try {
        // const smc = getContractByName(CONTRACT_NAME.MIGRATION);
        const chainId = rootState.web3.chainId;
        const smc = getContractMigration(chainId);
        const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
        const res: boolean = await contractInstance.methods.isReady().call();
        commit('setIsReady', res);
        resolve(res);
      } catch (error) {
        resolve(false);
      }
    });
  },
  async getTimeClaim({ state, commit, dispatch, rootState, rootGetters }) {
    try {
      const chainId: number = rootState.web3.chainId;
      const smc = getContractClaim(chainId);
      if (smc && smc !== undefined) {
        const abi = getAbiByContract(smc?.addr);
        const network = getConfigChain(smc?.network as number);
        const web3: any = new Web3(new Web3.providers.HttpProvider(network.rpcUrl));
        const contractInstance = new web3.eth.Contract(abi, smc?.addr);
        // const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
        const rangeTimeClaim = await contractInstance.methods.getTime().call();
        return rangeTimeClaim;
      }
    } catch (error) {
      return [0, 0];
    }
  },
  async getStateMigration({ commit, dispatch, rootState }) {
    try {
      // const smc = getContractByName(CONTRACT_NAME.MIGRATION);
      const chainId = rootState.web3.chainId;
      const smc = getContractMigration(chainId);
      const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
      const [season, timeStart, timeEnd, total, timeClaim]: number[] = await Promise.all([
        contractInstance.methods.season().call(),
        contractInstance.methods.timeStartMigration().call(),
        contractInstance.methods.timeEndMigration().call(),
        contractInstance.methods.totalMigration().call(),
        dispatch('getTimeClaim')
      ]);
      commit('setSeason', season);
      commit('setTimeMigration', { from: timeStart, to: timeEnd });
      commit('setAllMigrated', total);
      //@ts-ignore
      commit('setTimeClaim', { from: timeClaim[0], to: timeClaim[1] });
    } catch (error) {
      // console.log('getStateMigration', error);
    }
  },
  checkTokenExists({ dispatch, rootState }, { tokenId, isV1 }: { tokenId: number; isV1: boolean | false }) {
    return new Promise<boolean>(async resolve => {
      try {
        if (tokenId > 0) {
          // const smc = getContractByName(CONTRACT_NAME.MIGRATION);
          const chainId = rootState.web3.chainId;
          const smc = getContractMigration(chainId);
          const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
          const res: boolean = await contractInstance.methods.checkTokenExists(tokenId, isV1).call();
          resolve(res);
        } else {
          resolve(false);
        }
      } catch (error) {
        resolve(false);
      }
    });
  },
  getMigrtionInfoByAddress({ dispatch, commit, rootState }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!rootState.web3.isLogin) await dispatch('web3/connect', null, { root: true });
        const account = rootState.web3.account;
        if (account) {
          // const smc = getContractByName(CONTRACT_NAME.MIGRATION);
          const chainId = rootState.web3.chainId;
          const smc = getContractMigration(chainId);
          const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
          const res: any = await contractInstance.methods.migrationInfo(account).call();
          // commit('setV1Migrated', res.totalv1);
          // commit('setV2Migrated', res.totalv2);
          resolve({ totalv1: parseInt(res.totalv1), totalv2: parseInt(res.totalv2) });
        }
      } catch (error) {
        resolve({ totalv1: 0, totalv2: 0 });
      }
    });
  },
  getPetMigrated({ state, commit, dispatch, rootState }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!rootState.web3.isLogin) await dispatch('web3/connect', null, { root: true });
        const account = rootState.web3.account;
        if (account) {
          // const smc = getContractByName(CONTRACT_NAME.MIGRATION);
          const chainId = rootState.web3.chainId;
          const smc = getContractMigration(chainId);
          const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
          const v1: string = await contractInstance.methods.v1().call();
          const v2: string = await contractInstance.methods.v2().call();
          const res: { idsPetV1: number[]; idsPetV2: number[] } = await contractInstance.methods.getPetMigrated(account).call();

          const ids = res.idsPetV1.concat(res.idsPetV2);

          const petMigrated = await Promise.all([
            ...res.idsPetV1.map((tokenId: number) => {
              const contract = getContractByAddress(v1.toLowerCase());
              const network = getConfigChain(contract.network);

              return dispatch('parseDetailPet', { tokenId, chainName: network.chainIcon, addr: contract.addr, version: 1 });
            }),
            ...res.idsPetV2.map((tokenId: number) => {
              const contract = getContractByAddress(v2.toLowerCase());
              const network = getConfigChain(contract.network);
              return dispatch('parseDetailPet', { tokenId, chainName: network.chainIcon, addr: contract.addr, version: 2 });
            })
          ]);

          commit('setNFTMigrated', petMigrated);
          resolve(petMigrated);
        }
      } catch (error) {
        resolve([]);
      }
    });
  },
  async forceUpdateData({ dispatch, commit, state, rootState }) {
    try {
      if (rootState.web3.isLogin) {
        const address = rootState.web3.account;
        const season = state.season;
        const amount = 99999;
        const petV1KAI = getContractByName(CONTRACT_NAME.KAI_PET_V1) as IContract;
        const petV2KAI = getContractByName(CONTRACT_NAME.KAI_PET_V2) as IContract;
        const petV1BSC = getContractByName(CONTRACT_NAME.BSC_PET_V1) as IContract;
        const petV2BSC = getContractByName(CONTRACT_NAME.BSC_PET_V2) as IContract;
        await Promise.all([
          dispatch('postUpdateSubgraph', { address, network: petV1KAI.network.toString(), nft: petV1KAI.addr, season, amount }),
          dispatch('postUpdateSubgraph', { address, network: petV2KAI.network.toString(), nft: petV2KAI.addr, season, amount }),
          dispatch('postUpdateSubgraph', { address, network: petV1BSC.network.toString(), nft: petV1BSC.addr, season, amount }),
          dispatch('postUpdateSubgraph', { address, network: petV2BSC.network.toString(), nft: petV2BSC.addr, season, amount })
        ]);

        setTimeout(() => {
          dispatch('getSubmited');
        }, 15000);
      }
    } catch (error) {
      console.log(`forceUpdateData Err`, error);
    }
  },
  postUpdateSubgraph({}, payload) {
    this.$axios.setHeader('Content-Type', 'application/json', ['post']);
    this.$axios.setHeader('Accept', 'application/json');
    return this.$axios.$post(`${configApi.migration}/users/update/`, payload);
  },
  callUpdateSubgraph({ dispatch, commit, state }, { payload, time }: { payload: object; time: number }) {
    try {
      this.$axios.setHeader('Content-Type', 'application/json', ['post']);
      this.$axios.setHeader('Accept', 'application/json');
      this.$axios.$post(`${configApi.migration}/users/update/`, payload).then(res => {
        if (res.update) {
          commit('app/setLoadCredit', true, { root: true });
          setTimeout(() => {
            //dispatch('getSubmited');
            commit('resetListNFT');
            dispatch('getDpetNFTs');
          }, time);
        }
      });
    } catch (error) {
      console.log('callUpdateSubgraph err', error);
    }
  },
  async checkUpdateInfo({ state, commit, dispatch, rootState }, time: number = 5000) {
    try {
      const isConnected = rootState.web3.isLogin;
      const account = rootState.web3.account;
      const chainId = rootState.web3.chainId;
      const season = state.season;
      if (isConnected) {
        const { totalv1, totalv2 } = await dispatch('getMigrtionInfoByAddress');
        if (state.storage.length > 0) {
          state.storage.map(async (item: any) => {
            if (item.address === DPET_SPIRIT) {
            } else {
              const smc = getContractByAddress(item.address.toLowerCase());
              if (smc.contractName.endsWith('PET_V1') && parseInt(season.toString()) === parseInt(item.season) && parseInt(item.total) !== parseInt(totalv1)) {
                dispatch('callUpdateSubgraph', {
                  payload: {
                    address: account,
                    network: smc.network.toString(),
                    nft: item.address,
                    season: parseInt(season.toString()),
                    amount: parseInt(totalv1)
                  },
                  time
                });
              } else if (smc.contractName.endsWith('PET_V2') && parseInt(season.toString()) === parseInt(item.season) && parseInt(item.total) !== parseInt(totalv2)) {
                dispatch('callUpdateSubgraph', {
                  payload: {
                    address: account,
                    network: smc.network.toString(),
                    nft: item.address,
                    season: parseInt(season.toString()),
                    amount: parseInt(totalv2)
                  },
                  time
                });
              }
            }
          });
        } else {
          if (totalv1 > 0) {
            let smc1;
            if (chainId === 5) {
              smc1 = getContractByName(CONTRACT_NAME.FAKE_PET_V1) as IContract;
            } else if (chainId === 56) {
              smc1 = getContractByName(CONTRACT_NAME.BSC_PET_V1) as IContract;
            } else if (chainId === 24) {
              smc1 = getContractByName(CONTRACT_NAME.KAI_PET_V1) as IContract;
            }
            dispatch('callUpdateSubgraph', {
              payload: {
                address: account,
                network: smc1?.network.toString(),
                nft: smc1?.addr,
                season: parseInt(season.toString()),
                amount: parseInt(totalv1)
              },
              time
            });
          }
          if (totalv2 > 0) {
            let smc2;
            if (chainId === 5) {
              smc2 = getContractByName(CONTRACT_NAME.FAKE_PET_V2) as IContract;
            } else if (chainId === 56) {
              smc2 = getContractByName(CONTRACT_NAME.BSC_PET_V2) as IContract;
            } else if (chainId === 24) {
              smc2 = getContractByName(CONTRACT_NAME.KAI_PET_V2) as IContract;
            }
            dispatch('callUpdateSubgraph', {
              payload: {
                address: account,
                network: smc2?.network.toString(),
                nft: smc2?.addr,
                season: parseInt(season.toString()),
                amount: parseInt(totalv2)
              },
              time
            });
          }
        }
      }
    } catch (error) {}
  },
  getSubmited({ dispatch, commit, state, rootState }) {
    return new Promise(async (resolve, reject) => {
      try {
        const isConnected = rootState.web3.isLogin;
        if (!isConnected) resolve([]);
        commit('app/setLoadCredit', true, { root: true });
        const wallet = rootState.web3.account;
        this.$axios.setHeader('Content-Type', 'application/json', ['post']);
        this.$axios.setHeader('Accept', 'application/json');
        this.$axios
          .$post(`${configApi.migration}/users/`, {
            wallet: wallet.toLowerCase()
          })
          .then(async (res: any) => {
            const { claimed, storage, claimedCount, pending }: { claimed: number; storage: object[]; claimedCount: number; pending: string | '' } = res;
            // commit('setClaimed', claimed);
            // commit('setStorage', storage);
            // commit('setTxPending', pending);
            // commit('setClaimedCount', claimedCount);
            commit('setInfoUser', {
              claimed,
              storage,
              claimedCount,
              pending
            });
            const parseData: IResultStorage = await dispatch('parseStorageData', storage);
            if (parseData) {
              const { totalCredit, totalCreditV1, totalCreditV2, totalCreditSpirit, petMigrated, spiritMigrated, totalPetV1, totalPetV2 } = parseData;
              // do something
              //commit('setTotalCredit', parseData.totalCredit);
              commit('setNFTMigrated', petMigrated);
              // commit('setNftSubmited', petMigrated);
              commit('setInfoTotal', {
                claimed,
                totalCredit,
                totalCreditV1,
                totalCreditV2,
                totalCreditSpirit,
                spiritMigrated,
                totalPetV1,
                totalPetV2
              });
            }
            resolve(parseData.petMigrated);
          })
          .catch(() => resolve([]))
          .finally(() => {
            commit('app/setLoadCredit', false, { root: true });
          });
      } catch (error) {
        resolve([]);
      }
    });
  },
  async getSpirit({ dispatch, commit, state, rootState }) {
    try {
      if (rootState.web3.isLogin && rootState.web3.chainId > 0) {
        const wallet: string = rootState.web3.account;
        const network: string = rootState.web3.chainId.toString();
        this.$axios.setHeader('Content-Type', 'application/json', ['post']);
        this.$axios.setHeader('Accept', 'application/json');
        const res = await this.$axios.$post(`${configApi.migration}/users/spirit/`, { wallet, network });
        if (res?.update) {
          setTimeout(() => {
            dispatch('getSpirit');
          }, 15000);
        } else if (res?.dpetSpirit) {
          commit('setDpetSpirit', res?.dpetSpirit);
        }
        return res;
      }
    } catch (error) {
      return null;
    }
  },
  async getClaim({ dispatch, commit, state, rootState }) {
    try {
      const wallet: string = rootState.web3.account;
      // const network: string = rootState.web3.chainId.toString();
      const network: string = '24';
      this.$axios.setHeader('Content-Type', 'application/json', ['post']);
      this.$axios.setHeader('Accept', 'application/json');
      const res = await this.$axios.$post(`${configApi.migration}/users/claim/`, { wallet, network });
      const { amount, claimedCount, pending } = res;
      commit('setTxPending', pending);
      commit('setClaimedCount', claimedCount);
      return res;
    } catch (error) {
      return null;
    }
  },
  checkClaim({ dispatch, commit, state, rootState }, time: number = 5000) {
    return new Promise((resolve, reject) => {
      try {
        const wallet: string = rootState.web3.account;
        const network: string = rootState.web3.chainId.toString();
        this.$axios.setHeader('Content-Type', 'application/json', ['post']);
        this.$axios.setHeader('Accept', 'application/json');
        this.$axios.$post(`${configApi.migration}/users/claimcheck/`, { wallet, network }).then(res => {
          if (res?.update) {
            commit('app/setLoadCredit', true, { root: true });
            setTimeout(() => {
              dispatch('getSubmited');
            }, time);
          }
          resolve(res?.update);
        });
      } catch (error) {
        resolve(true);
      }
    });
  },
  claimCredit({ state, commit, dispatch, rootState }) {
    return new Promise(async (resolve, reject) => {
      try {
        const chainId: number = rootState.web3.chainId;
        const wallet = rootState.web3.account;
        const checkNetwork: boolean = await dispatch('web3/checkNetWorkClaim', {}, { root: true });
        const network = getConfigChain(NETWORK_CLAIM);
        if (!checkNetwork) reject({ message: `Network not supported, please switch ${network?.chainName}` });
        const dataClaim = await dispatch('getClaim');
        if (dataClaim) {
          const smc = getContractClaim(chainId);
          const { claimedCount, amount, pending } = dataClaim;
          const contractInstance = await dispatch('web3/getInstanceContract', smc?.addr, { root: true });
          const res = await contractInstance.methods.claimCredit(amount, claimedCount, pending).send({
            from: wallet,
            gas: MAX_GAS_FEE
          });
          if (res?.status) {
            setTimeout(() => {
              dispatch('checkClaim', 15000);
              resolve(res);
            }, 4000);
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  async parseStorageData({ dispatch }, storage: any[]) {
    if (storage && storage.length > 0) {
      let totalPet: number = 0;
      let totalPetV1: number = 0;
      let totalPetV2: number = 0;
      let totalCredit: number = 0;
      let totalCreditV1: number = 0;
      let totalCreditV2: number = 0;
      let totalCreditSpirit: number = 0;
      let petMigrated: IPet[] = [];
      let spiritMigrated: number = 0;
      await Promise.all(
        storage.map(async (obj: any) => {
          let smcName: string;
          let pets: IPet[] = [];
          totalCredit = totalCredit + parseInt(obj?.credit);
          if (obj.address === DPET_SPIRIT) {
            totalCreditSpirit = totalCreditSpirit + parseInt(obj?.credit);
            spiritMigrated = spiritMigrated + parseInt(obj?.total);
          } else {
            totalPet = totalPet + parseInt(obj?.total);
            const contract = getContractByAddress(obj?.address);
            smcName = contract.contractName;
            switch (smcName) {
              case CONTRACT_NAME.BSC_PET_V1:
                totalPetV1 = totalPetV1 + parseInt(obj?.total);
                if (obj?.nfts && obj.nfts.length > 0) {
                  totalCreditV1 = totalCreditV1 + parseInt(obj?.credit);
                  pets = await Promise.all(obj.nfts.map((tokenId: number) => dispatch('parseDetailPet', { tokenId, chainName: 'bsc', addr: contract.addr, version: 1 })));
                  petMigrated = petMigrated.concat(pets);
                }
                break;
              case CONTRACT_NAME.BSC_PET_V2:
                totalPetV2 = totalPetV2 + parseInt(obj?.total);
                if (obj?.nfts && obj.nfts.length > 0) {
                  totalCreditV2 = totalCreditV2 + parseInt(obj?.credit);
                  pets = await Promise.all(obj.nfts.map((tokenId: number) => dispatch('parseDetailPet', { tokenId, chainName: 'bsc', addr: contract.addr, version: 2 })));
                  petMigrated = petMigrated.concat(pets);
                }
                break;
              case CONTRACT_NAME.KAI_PET_V1:
                totalPetV1 = totalPetV1 + parseInt(obj?.total);
                if (obj?.nfts && obj.nfts.length > 0) {
                  totalCreditV1 = totalCreditV1 + parseInt(obj?.credit);
                  pets = await Promise.all(obj.nfts.map((tokenId: number) => dispatch('parseDetailPet', { tokenId, chainName: 'kai', addr: contract.addr, version: 1 })));
                  petMigrated = petMigrated.concat(pets);
                }
                break;
              case CONTRACT_NAME.KAI_PET_V2:
                totalPetV2 = totalPetV2 + parseInt(obj?.total);
                if (obj?.nfts && obj.nfts.length > 0) {
                  totalCreditV2 = totalCreditV2 + parseInt(obj?.credit);
                  pets = await Promise.all(obj.nfts.map((tokenId: number) => dispatch('parseDetailPet', { tokenId, chainName: 'kai', addr: contract.addr, version: 2 })));
                  petMigrated = petMigrated.concat(pets);
                }
                break;
              //fake test
              case CONTRACT_NAME.FAKE_PET_V1:
                totalPetV1 = totalPetV1 + parseInt(obj?.total);
                if (obj?.nfts && obj.nfts.length > 0) {
                  totalCreditV1 = totalCreditV1 + parseInt(obj?.credit);
                  pets = await Promise.all(obj.nfts.map((tokenId: number) => dispatch('parseDetailPet', { tokenId, chainName: 'eth', addr: contract.addr, version: 1 })));
                  petMigrated = petMigrated.concat(pets);
                }
                break;
              case CONTRACT_NAME.FAKE_PET_V2:
                totalPetV2 = totalPetV2 + parseInt(obj?.total);
                if (obj?.nfts && obj.nfts.length > 0) {
                  totalCreditV2 = totalCreditV2 + parseInt(obj?.credit);
                  pets = await Promise.all(obj.nfts.map((tokenId: number) => dispatch('parseDetailPet', { tokenId, chainName: 'eth', addr: contract.addr, version: 2 })));
                  petMigrated = petMigrated.concat(pets);
                }
                break;
              default:
                break;
            }
          }
          return pets;
        })
      );
      return { totalCredit, totalPet, totalPetV1, totalPetV2, petMigrated, totalCreditV1, totalCreditV2, totalCreditSpirit, spiritMigrated };
    } else {
      return null;
    }
  },
  parseDetailPet({}, { tokenId, chainName, addr, version }): IPet {
    return {
      tokenId,
      name: `Pet #${tokenId}`,
      version: version,
      image: `https://cdn.agoran.io/DPet/my_defipet-${chainName}-${addr}-${tokenId}.jpeg`,
      chain: chainName,
      addr
    };
  },

  async fecthMyMigrated({ state, commit, dispatch, rootState }) {
    try {
    } catch (error) {
      console.log('fecthMyMigrated', error);
    }
  },
  migration({ state, getters, commit, dispatch, rootState }, pet: IPet) {
    return new Promise(async (resolve, reject) => {
      try {
        const chainId = rootState.web3.chainId;
        const account = rootState.web3.account;
        // const smc = getContractByName(CONTRACT_NAME.MIGRATION);
        const smc = getContractMigration(chainId) as IContract;
        const { tokenId, version, addr } = pet;
        if (await dispatch('web3/checkNetWorkSupport', chainId, { root: true })) {
          const contractInstance = await dispatch('web3/getInstanceContract', smc.addr, { root: true });
          const [isReady, isExists] = await Promise.all([contractInstance.methods.isReady().call(), contractInstance.methods.checkTokenExists(tokenId, version === 1).call()]);
          if (!isReady) reject({ message: 'Can not migration now' });
          if (isExists) reject({ message: 'Token has migrated' });
          const tokenApprove = await dispatch('web3/approveToken', { tokenAddress: addr, tokenId, exchangeAdress: smc.addr }, { root: true });
          if (!tokenApprove) {
            reject({ message: 'You have not granted access permission' });
          } else {
            // @ts-ignore
            this.$processing.start(`Pet migration is in progress`);
            const res = await contractInstance.methods.migration(tokenId, version === 1).send({ from: account, gas: MAX_GAS_FEE });
            setTimeout(() => {
              dispatch('checkUpdateInfo', 10000);
              // dispatch('forceUpdateData');
              // @ts-ignore
              this.$processing.finish();
              resolve({ success: true, data: res });
            }, 1000);
          }
        }
      } catch (error) {
        console.log('migration error', error);
        // @ts-ignore
        this.$processing.finish();
        reject(error);
      }
    });
  },

  migrationSpirit({ commit, dispatch, rootState }) {
    return new Promise(async (resolve, reject) => {
      try {
        const network = rootState.web3.chainId.toString();
        const wallet = rootState.web3.account;
        const signature = await dispatch('web3/getSignature', 'Dpet Spirit Migration', { root: true });
        if (signature) {
          this.$axios.setHeader('Content-Type', 'application/json', ['post']);
          this.$axios.setHeader('Accept', 'application/json');
          this.$axios
            .$post(`${configApi.migration}/users/migrate-spirit/`, { wallet, network, signature })
            .then(res => {
              dispatch('getSubmited');
              resolve(res);
            })
            .catch(err => reject(err));
        } else {
          reject({ message: 'Not found signature' });
        }
      } catch (error) {
        console.log('migrationSpirit error', error);
        // @ts-ignore
        this.$processing.finish();
        reject(error);
      }
    });
  },

  beforeMigration({ state, commit, dispatch, rootState }, nft: IPet) {
    // tokenId: number;
    // name: string;
    // image: string;
    // version: number;
    // chain: string;
    // addr: string;
    const { tokenId, addr, chain } = nft;
    return new Promise(async (resolve, reject) => {
      try {
        const address = rootState.web3.account;
        const contract = getContractByAddress(addr);
        const network = contract.network;
        // resolve({
        //   tokenId: '10023278',
        //   network: '56',
        //   nft: '0x4f3110435c9626418063add7897f08088838fc8b',
        //   rarity: 'Legendary',
        //   credit: 54,
        //   dpetSpirit: [0, 10, 11, 12, 13]
        // });
        this.$axios.setHeader('Content-Type', 'application/json', ['post']);
        this.$axios.setHeader('Accept', 'application/json');
        this.$axios
          .$post(`${configApi.migration}/users/nft-detail`, { address, network: network.toString(), nft: addr, tokenId: tokenId.toString() })
          .then(res => {
            resolve(res);
          })
          .catch(err => reject(err));
      } catch (error) {
        console.log('beforeMigration error', error);
        reject(error);
      }
    });
  },

  loadDataCSV({ state, commit }) {
    return new Promise<void>((resolve, reject) => {
      try {
        fetch('https://mydefipet.com/allUsersMDP_Final.csv')
          .then(response => response.text())
          .then(text => {
            const data = text.split('\r\n');
            data.shift();
            const dataCSV = data.map((row: string, idx: number): IUser => {
              const columns = row.split(',');
              return {
                rank: idx + 1,
                owner: columns[0],
                bsc: parseInt(columns[1]),
                kai: parseInt(columns[2]),
                point: parseInt(columns[3])
              };
            });
            commit('setDataCSV', dataCSV);
          })
          .catch(error => {
            console.error('Error fetching CSV data:', error);
          });
      } catch (error) {
        console.error('loadDataCSV err:', error);
      }
    });
  }
};

export default actions;
