import { MutationTree } from 'vuex';
import { ButtonState, IAppState } from './state';

const mutations: MutationTree<IAppState> = {
  setAppLoading(state, isLoading) {
    state.isAppLoading = isLoading;
  },
  setOpenModalWallet(state, isOpenModalWallet) {
    state.isOpenModalWallet = isOpenModalWallet;
  },
  setLoadCredit(state, isLoadCredit) {
    state.isLoadCredit = isLoadCredit;
  }
};

export default mutations;
