import * as funcHelpers from '@/libs/helper';
import events from './confirm/events';
import * as eventComponent from '@/components/events';
import { IPopupMessage, Button, eventDialogMsg } from './dialogMessage/events';
interface IConfirm {
  title: string;
  message: string;
  button: {
    yes: string;
    no: string;
  };
  callback: any;
}

export default ({ app, store }: any, inject: any) => {
  inject('helper', funcHelpers),
    inject('modalWallet', {
      show(params: any) {
        // store.commit('app/setOpenModalWallet', true);
        eventComponent.eventModalWallet.$emit('show', params);
      },
      hide(params: any) {
        //store.commit('app/setOpenModalWallet', false);
        eventComponent.eventModalWallet.$emit('hide', params);
      }
    }),
    inject('loadingPage', {
      start() {
        store.commit('app/setAppLoading', true);
      },
      finish() {
        store.commit('app/setAppLoading', false);
      }
    }),
    inject('processing', {
      start(text: string = '') {
        eventComponent.eventProcessing.$emit('start', text);
      },
      finish() {
        eventComponent.eventProcessing.$emit('finish');
      }
    }),
    inject('modalConnect', {
      show(params: { onResolve: () => void; onReject: () => void }) {
        eventComponent.eventModalConnect.$emit('show', params);
      },
      hide() {
        eventComponent.eventModalConnect.$emit('hide');
      }
    }),
    inject('confirm', (params: IConfirm) => {
      if (typeof params === 'object') {
        if (params.hasOwnProperty('callback') && typeof params.callback != 'function') {
          let callbackType = typeof params.callback;
          throw new Error(`Callback type must be an function. Caught: ${callbackType}. Expected: function`);
        }
        events.$emit('open', params);
      }
    }),
    inject('dialogMsg', {
      show(params: IPopupMessage) {
        eventDialogMsg.$emit('open', params);
      },
      close(cb = () => {}) {
        eventDialogMsg.$emit('close', cb);
      }
    }),
    inject('modalResult', {
      show(params: any) {
        eventComponent.eventModalResult.$emit('show', params);
      },
      hide(params: any) {
        eventComponent.eventModalResult.$emit('hide', params);
      }
    });
};
