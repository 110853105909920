export interface ButtonState {
  title: string;
  action: any; //0: close, 2: Wallet Info, string: route
}
export interface IAppState {
  app?: any;
  isAppLoading: boolean;
  isOpenModalWallet: boolean;
  isLoadCredit: boolean;
}

const state = (): IAppState => ({
  isAppLoading: false,
  isOpenModalWallet: false,
  isLoadCredit: false
});

export default state;
