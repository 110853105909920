import { MutationTree } from 'vuex';
import { InterfaceWeb3 } from './state';

const mutations: MutationTree<InterfaceWeb3> = {
  setAccount(state, account) {
    if (state.account !== account) {
      if (account) account = account.toLocaleLowerCase();
      state.account = account;
      state.isLogin = true;
    }
  },
  setChainId(state, chainId) {
    chainId = parseInt(chainId);
    if (state.chainId !== chainId) {
      state.chainId = chainId;
    }
  },

  resetState(state) {
    state.isLogin = false;
    state.account = '';
    state.chainId = 0;
  },
};

export default mutations;
