import _ from 'lodash';
export default function ({ route, redirect, app }: any) {
  if (route.path.endsWith('/index.html')) {
    if (!_.isEmpty(route.query)) {
      const path = route.fullPath.replace('index.html', '');
      return redirect(path);
    } else {
      return redirect(route.path.replace('index.html', ''));
    }
  }
}
